<template>
	<section v-if="news.length" class="main-content news-section">
		<div class="row row-header align-center">
			<div class="icon-flower" />
			<h2 class="subtitle">{{ defaults[locale].homepage.sectionNewsTitle }}</h2>
			<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
		</div>
		<div class="row flex-row">
			<div class="flex-row column6">
				<div v-for="item in news.slice(0, 1)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-arrow">
								<span>{{ $t('readMore') }}</span>
								<span class="button-icon">
									<font-awesome-icon icon="fa-light fa-chevron-right" size="1x" />
								</span>
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
			<div class="flex-row column6">
				<div v-for="item in news.slice(1, 5)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-arrow">
								<span>{{ $t('readMore') }}</span>
								<span class="button-icon">
									<font-awesome-icon icon="fa-light fa-chevron-right" size="1x" />
								</span>
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	news: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.news-section {
	background: var(--contentblock-background-color);
}

.icon-flower {
	content: '';
	background: url('~/assets/images/icon-flower.png') no-repeat center center;
	background-size: 42px;
	height: 42px;
	width: 42px;
	display: block;
	margin: 0 auto 30px;

	&.light {
		background: url('~/assets/images/icon-flower-light.png') no-repeat center center;
		background-size: 42px;
	}
}

.event-date {
	color: #fff;
	font-size: 14px;
	margin: 0 0 10px;
	display: inline-block;
	width: 100%;
	letter-spacing: 2px;
}

.block-item {
	a {
		color: #fff;

		&:hover {
			.button-icon {
				color: #fff;
				background: var(--cta-color-hover);
			}
		}
	}

	.button-arrow {
		text-transform: uppercase;
		font-weight: 500;
		font-size: calc(var(--body-font-size) - 2px);
		letter-spacing: 2px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		.button-icon {
			background: var(--cta-color);
			width: 37px;
			height: 37px;
			text-align: center;
			line-height: 37px;
			font-size: 20px;
			color: #fff;
			display: block;
			margin: 0 0 0 15px;
			transition: all 0.3s ease-in-out;
		}
	}
}
</style>
