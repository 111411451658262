<template>
	<section class="promotions main-content align-center">
		<div class="row row-header align-center">
			<div v-if="subtitle" class="icon-flower" />
			<h2 class="subtitle">{{ subtitle }}</h2>
			<h2>{{ header }}</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row flex-row slide-row">
			<client-only>
				<nuxt-link
					v-for="room in items.slice(0, 4)"
					:key="room.ID"
					:to="localePath(`/${room.filename}`)"
					class="slick-item"
				>
					<div class="slick-slide-content">
						<h3>{{ room.header }}</h3>
					</div>
					<picture>
						<source
							v-if="room.imageOverviewPortraitWebp"
							:srcset="room.imageOverviewPortraitWebp"
							type="image/webp"
						/>
						<source :srcset="room.imageOverviewPortrait" />
						<img loading="lazy" :src="room.imageOverviewPortrait" :alt="room.imageOverviewPortraitAlt" />
					</picture>
					<div class="promoblock-button">
						<span>{{ defaults[locale].rooms.view }}</span>
						<span class="button-icon">
							<font-awesome-icon icon="fa-light fa-chevron-right" size="1x" />
						</span>
					</div>
				</nuxt-link>
			</client-only>
		</div>
		<div class="row align-center">
			<div class="columns column12">
				<br />
				<nuxt-link class="button" :to="localePath('rooms')">
					{{ defaults[locale].rooms.more }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.contentblocks {
	background: var(--contentblock-background-color);
}

.row-header {
	max-width: 980px;
}

.icon-flower {
	content: '';
	background: url('~/assets/images/icon-flower.png') no-repeat center center;
	background-size: 42px;
	height: 42px;
	width: 42px;
	display: block;
	margin: 0 auto 30px;

	&.light {
		background: url('~/assets/images/icon-flower-light.png') no-repeat center center;
		background-size: 42px;
	}
}

.slick-item {
	img {
		height: calc(100% - 47px);
	}

	&:hover {
		.button-icon {
			color: #fff;
			background: var(--cta-color-hover);
		}
	}
}

.promoblock-button {
	width: 100%;
	background: #fff;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 5px 5px 5px 12px;
	color: var(--body-color);
	text-transform: uppercase;
	font-weight: 500;
	font-size: calc(var(--body-font-size) - 2px);
	letter-spacing: 2px;

	.button-icon {
		background: var(--cta-color);
		width: 37px;
		height: 37px;
		text-align: center;
		line-height: 37px;
		font-size: 20px;
		color: #fff;
		transition: all 0.3s ease-in-out;
	}
}
</style>
